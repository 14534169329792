.navbar {
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    width: 100%;
    min-height: 75px;
    padding: 1rem;
    background: var(--color-secondary);
    color: var(--color-text-light);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.navbar__links {
    display: flex;
}
.navbar__link {
    padding: 1rem;
    color: inherit;
    font-size: 1.2rem;
}
.navbar__link:hover {
    color: var(--color-tercary);
}
.navbar__link:active {
    color: var(--color-tercary);
}
.navbar__burger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    display: none;
}

.navbar__logo img {
    border-radius: var(--border-radius);
}

@media screen and (max-width: 920px) {
    .navbar__links {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: -100vw;
        bottom: 0;
        width: 0;
        height: 100vh;
        padding: 2rem;
        visibility: hidden;
        background: var(--color-secondary);
        transition: all .8s ease-out;
    }
    .show-nav .navbar__links {
        width: 100%;
        right: 0;
        visibility: visible;
    }
    .navbar__item::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 3vw;
        height: 1px;
        background: var(--color-text-light);
    }
    .navbar__item:last-child::after {
        display: none;
    }
    .navbar__link {
        display: block;
        padding: 1.5rem;
        font-size: 5vw;
    }

    /*BURGER*/
    .navbar__burger {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
    }
    .navbar__burger:hover {
        cursor: pointer;
    }
    .burger-bar,
    .burger-bar::before,
    .burger-bar::after {
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: var(--color-text-light);
        transition: all .5s ease-in-out;
    }
    .burger-bar::before,
    .burger-bar::after {
        content: "";
        position: absolute;
        left: 0;
    }
    .burger-bar::before {
        transform: translateY(-12px);
    }
    .burger-bar::after {
        transform: translateY(12px);
    }
    .show-nav .burger-bar {
        width: 0;
        background: transparent;
    }
    .show-nav .burger-bar::before {
        transform: rotate(45deg);
    }
    .show-nav .burger-bar::after {
        transform: rotate(-45deg);
    }
    /* Burger bottun animation */
    .navbar__item {
        transform: translateY(100vh);
    }
    .show-nav .navbar__item {
        transform: translateY(0);
    }
    .show-nav .slideInDown-1 {
        transition: all 1s ease-out;
    }
    .show-nav .slideInDown-2 {
        transition: all 1.1s ease-out;
    }
    .show-nav .slideInDown-3 {
        transition: all 1.2s ease-out;
    }
    .show-nav .slideInDown-4 {
        transition: all 1.3s ease-out;
    }
    .show-nav .slideInDown-5 {
        transition: all 1.4s ease-out;
    }
    .show-nav .slideInDown-6 {
        transition: all 1.5s ease-out;
    }
}