#gammes {
    background: var(--color-tercary);
    transition: all 20s ease-in-out;
}

/******/
.gammes__title {
    background: var(--color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.gammes__title h2 {
    border-bottom: solid 2px var(--color-text-light);
}



img {
    display: block;
    width: 100%;
    border-radius: var(--border-radius-left);
    object-fit: cover;

}

.signet__img__little {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.signet__img__little img {
    display: block;
    width: 80px;
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.gammes__signet {
    display: flex;
    flex-direction: column;
}

.signet {
    display: grid;
    grid-template-columns: 35% 10% 55%;
    background: var(--color-secondary);
    border-radius: var(--border-radius);
    margin-bottom: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.signet__img {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);

}

.signet__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.signet__content h3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signet__carac ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 0.9rem;
    padding: 0.5rem;
}

.signet__info p {
    font-size: 0.9rem;
    padding: 0.5rem;
}

.fullscreen-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.open {
    animation: opening 1s ease-in-out;
  }
  
  .closed::before {
    animation: closing 1s ease-in-out;
  }
  
  @keyframes opening {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes closing {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  

.fullscreen-image img {
    max-width: 80%;
    max-height: 80%;
    object-fit: cover;
    border-radius: var(--border-radius);
    
}

.signet__img a:hover {
    cursor: pointer;
   
}
.signet__img__little a:hover {
    cursor: pointer;
}
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--color-primary);
    font-size: 40px;
    line-height: 40px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: opening 2s ease-in-out;
    background: transparent;
}
.web__divider__border {
    margin-top: 0.5rem;
    width: 50px;
    height: 2px;
    background: var(--color-primary);
    border-radius: var(--border-radius);
}




@media screen and (max-width:820px) {
    .signet {
        display: flex;
        flex-direction: column;
        background: var(--color-secondary);
        border-radius: var(--border-radius);
        margin-bottom: 1rem;
        padding: 0.5rem;
        margin: 1rem 3rem 1rem 3rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .signet__content h3 {
        margin: 1rem 0 1rem 0;
    }
    .web__divider__border {
        margin-bottom: 1rem;
    }
    .signet__img {
        display: block;
    }
    .signet__img img {
        border-radius: var(--border-radius-top) !important;
    }
    .signet__img__little {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0.5rem;
    }
    

}

@media screen and (max-width:767px) {
    .signet {
        display: flex;
        flex-direction: column;
        margin: 0.5rem;
    }
    .signet__content h3 {
        margin: 0.2rem 0 0.2rem 0;
    }
    .web__divider__border {
        margin-bottom: 0.2rem;
    }
    .signet__img img {
        border-radius: var(--border-radius-top) !important;
    }

    .signet__img__little {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 0.5rem;
    }
    
}