@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}
html {
  scroll-behavior: smooth;
}

:root {
  --color-bg: #f5f5f5;
  /*#67A0D3 #303030 #E8E5DA*/
  --color-primary: rgb(0, 106, 209);
  --color-primary-light: rgba(103, 160, 211, 0.6);
  --color-secondary: rgba(73, 82, 90, 1);
  --color-secondary-middle: rgba(73, 82, 90, 0.6);
  --color-secondary-light : rgba(73, 82, 90, 0.1);
  --color-tercary: rgba(220, 220, 220, 0.3);
  --color-tercary-dark: rgba(211, 211, 211, 0.8);
  --color-text-dark: #02040F;
  --color-text-light: #f5f5f5;

  --border-radius : 15px; 
  --border-radius-top: 15px 15px 0 0;
  --border-radius-left : 15px 0 0 15px; 
  --tansition : all .5s ease-in-out;

}

body {
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-bg);
  color: var(--color-text-light);
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#home, #gammes, #contact, #footer {
  max-width: 1040px;
  margin-right: auto;
  margin-left: auto;
}
#home {
  padding: 74px 0 0;
}
h4 {
  letter-spacing: 1px;
}

